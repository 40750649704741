.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 2s ease;
}

.reveal.active {
  transform: translateY(0px);
  opacity: 1;
}
